<template>
  <div>
    <!-- uploadC9A809AE-5B68-4F14-9DF8-147533D9D03B.swf -->
    <!-- pic6EFBD99B4865E90CF70D7BE874150EE0.jpg -->
    <img class="img-large rounded" src="">
    <p>
      The LEGO Universe team had a blast building fun and friendships at LEGO Club day, Germany! The event was a smashing success where more than a thousand fans were wowed by a LEGO Universe video presentation. Fans also participated in fun Club activities, hobnobbed with representatives from LEGOLAND, and received thousands of special LEGO Universe minifigures and exclusive bricks bearing the game's logo!
    </p>
    <p>
      These are just a few of the Club members the LEGO Universe team befriended at the event:
    </p>
    <p>
      Champion22, lucas5856, felix4402, torsten300, and jalko-carsten.
    </p>
    <p>
      Thanks for stopping by!
    </p>
  </div>
</template>
